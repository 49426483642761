import styled from "styled-components"
import carrierBackground from "../../images/messenger.svg"

export const Overriden = styled.div`
  position: fixed;
  z-index: 2147483646;
  right: 24px;
  bottom: 24px;
  width: 60px;
  height: 60px;
  background: url(${carrierBackground});
  padding: 0px;
  border-radius: 60px;
  pointer-events: none;
`