exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-bochnia-js": () => import("./../../../src/pages/contact/bochnia.js" /* webpackChunkName: "component---src-pages-contact-bochnia-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-niepolomice-js": () => import("./../../../src/pages/contact/niepolomice.js" /* webpackChunkName: "component---src-pages-contact-niepolomice-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kariera-js": () => import("./../../../src/pages/kariera.js" /* webpackChunkName: "component---src-pages-kariera-js" */),
  "component---src-pages-kontakt-bochnia-js": () => import("./../../../src/pages/kontakt/bochnia.js" /* webpackChunkName: "component---src-pages-kontakt-bochnia-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-kontakt-niepolomice-js": () => import("./../../../src/pages/kontakt/niepolomice.js" /* webpackChunkName: "component---src-pages-kontakt-niepolomice-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka_prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-services-financing-js": () => import("./../../../src/pages/services/financing.js" /* webpackChunkName: "component---src-pages-services-financing-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-movies-js": () => import("./../../../src/pages/services/movies.js" /* webpackChunkName: "component---src-pages-services-movies-js" */),
  "component---src-pages-services-purchase-js": () => import("./../../../src/pages/services/purchase.js" /* webpackChunkName: "component---src-pages-services-purchase-js" */),
  "component---src-pages-services-rent-js": () => import("./../../../src/pages/services/rent.js" /* webpackChunkName: "component---src-pages-services-rent-js" */),
  "component---src-pages-services-selling-js": () => import("./../../../src/pages/services/selling.js" /* webpackChunkName: "component---src-pages-services-selling-js" */),
  "component---src-pages-services-tenancy-js": () => import("./../../../src/pages/services/tenancy.js" /* webpackChunkName: "component---src-pages-services-tenancy-js" */),
  "component---src-pages-services-valuation-js": () => import("./../../../src/pages/services/valuation.js" /* webpackChunkName: "component---src-pages-services-valuation-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-uslugi-filmy-js": () => import("./../../../src/pages/uslugi/filmy.js" /* webpackChunkName: "component---src-pages-uslugi-filmy-js" */),
  "component---src-pages-uslugi-finansowanie-js": () => import("./../../../src/pages/uslugi/finansowanie.js" /* webpackChunkName: "component---src-pages-uslugi-finansowanie-js" */),
  "component---src-pages-uslugi-index-js": () => import("./../../../src/pages/uslugi/index.js" /* webpackChunkName: "component---src-pages-uslugi-index-js" */),
  "component---src-pages-uslugi-najem-js": () => import("./../../../src/pages/uslugi/najem.js" /* webpackChunkName: "component---src-pages-uslugi-najem-js" */),
  "component---src-pages-uslugi-sprzedaz-js": () => import("./../../../src/pages/uslugi/sprzedaz.js" /* webpackChunkName: "component---src-pages-uslugi-sprzedaz-js" */),
  "component---src-pages-uslugi-wycena-js": () => import("./../../../src/pages/uslugi/wycena.js" /* webpackChunkName: "component---src-pages-uslugi-wycena-js" */),
  "component---src-pages-uslugi-wynajem-js": () => import("./../../../src/pages/uslugi/wynajem.js" /* webpackChunkName: "component---src-pages-uslugi-wynajem-js" */),
  "component---src-pages-uslugi-zakup-js": () => import("./../../../src/pages/uslugi/zakup.js" /* webpackChunkName: "component---src-pages-uslugi-zakup-js" */),
  "component---src-pages-wyszukiwarka-js": () => import("./../../../src/pages/wyszukiwarka.js" /* webpackChunkName: "component---src-pages-wyszukiwarka-js" */),
  "component---src-templates-offer-js": () => import("./../../../src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-offer-print-js": () => import("./../../../src/templates/offer-print.js" /* webpackChunkName: "component---src-templates-offer-print-js" */)
}

