import React, { useEffect, useRef } from "react"
import { Overriden } from "./style"

export const CustomerChat = () => {
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        xfbml: true,
        version: "v13.0",
      })
    }
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js"
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")

    const chatbox = document.getElementById("fb-customer-chat")

    if (chatbox) {
      chatbox.setAttribute("page_id", "100527842378255")
      chatbox.setAttribute("attribution", "biz_inbox")

      chatbox.style.backgroundColor = 'red';
    }
  })

  return (
    <>
      <Overriden />
      <div id="fb-root" style={{ background: "yellow" }}></div>
      <div id="fb-customer-chat" className="fb-customerchat"></div>
    </>
  )
}
