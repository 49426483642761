import React from "react"
import { Helmet } from "react-helmet"

export default function RootLayout({ children }) {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1.0, maximum-scale = 1.0, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable = no"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap"
        />
      </Helmet>
      {children}
    </>
  )
}
